.testimonials {
  padding-top: 0;
  overflow: hidden;

  &__title-wrap {
    position: relative;
    padding: 35px 0;
    @include media-breakpoint-up(md) {
      padding: 60px 0;
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    width: 221px;
    height: 45px;
    padding-left: 25px;
    @include media-breakpoint-up(md) {
      padding-left: 55px;
      margin-left: 61px;
      width: 409px;
      height: 81px;
    }
    h2 {
      display: block;
      color: white;
      position: relative;
      line-height: 1;
      margin-bottom: 7px;

      font-size: 2.4rem;
      @include media-breakpoint-up(md) {
        font-size: 4rem;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &--full {
      width: 556px;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 5px;;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }
  }

  .abstract {
    position: absolute;
    width: 143px;
    top: 0;
    height: auto;
    left: -37px;

    @include media-breakpoint-up(md) {
      width: 250px;
      left: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
        width: 800px;
    }
    @include media-breakpoint-up(xl) {
        width: 1250px;
    }

    margin: 0 auto;
  }

  .swiper-container {
    position: relative;
    max-width: 100%;
    overflow: visible;
  }

  .swiper-next,
  .swiper-prev {
    position: absolute;
    top: 50%;
    width: 47px;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
    z-index: 20;

    &.swiper-button-disabled {
      opacity: 0.5;
    }

    @include media-breakpoint-up(md) {
      width: 77px;
    }
  }

  .swiper-next {
    right: 0;
    transform: translateY(-50%);
    @include media-breakpoint-up(md) {
      transform: translateY(-50%) translateX(35%);
    }
  }
  .swiper-prev {
    left: 0;
    transform: translateY(-50%);
    @include media-breakpoint-up(md) {
      transform: translateY(-50%) translateX(-55%);
    }
  }

  .swiper-slide {
    position: relative;
    color: white;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__inner {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 253px;
      height: 241px;
      padding: 36px 20px 30px;

      @include media-breakpoint-up(sm) {
          width: 320px;
          height: 300px;
          justify-content: center;
      }
      @include media-breakpoint-up(md) {
        justify-content: space-between;
        width: 368px;
        height: 343px;
        padding: 27px 45px 0;
      }

      max-width: 100%;

    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: -1;
    }

    &__title {
      @include font-responsive(false, false, 1.3rem, 1.9rem);
      font-weight: 700;
      margin-bottom: 5px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        padding-bottom: 20px;
      }


    }

    &__content {
    }

    &__copy {
      position: relative;
      line-height: 1.3;

      p {
        margin-bottom: 0;

        letter-spacing: -0.02em;
        @include media-breakpoint-up(md) {
          letter-spacing: initial;
          font-size: 1.4rem;
        }
      }
    }

    &__stars {
        display: block;
      width: 112px;
      height: auto;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
    
      svg {
        width: 100%;
        height: auto;
      }
      @include media-breakpoint-up(md) {
          margin-bottom: 0;
            width: 160px;
      }
      @include media-breakpoint-up(xl) {
      }
    }

    &__quotes {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
      svg {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%) translateY(-50%);
        width: 40px;
        height: auto;
        + svg {
          top: 100%;
          right: 0;
          left: auto;
          transform: translateX(40%) translateY(-70%);
        }

        @include media-breakpoint-up(md) {
          width: 57px;
        }
      }
    }

    &__footing {
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 35px;

      display: none;

      padding-bottom: 45px;
      margin-top: 33px;

      @include media-breakpoint-up(md) {
        display: flex;
        margin-top: 33px;
      }

      a {
        display: inline-block;
        height: 20px;
        width: auto;
        margin: 0 5px;
        pointer-events: none;
        svg {
          height: 100%;
          width: auto;
        }
      }

      font-size: 1.2rem;
    }
    &__divider {
      margin: 0 15px;
    }
  }

  &--retail {
    padding-top: 105px;
    @include media-breakpoint-up(xl) {
      padding-top: 120px;
    }
    .testimonials__title-wrap {
      position: static;
      padding: 20px 30px;
      @include media-breakpoint-up(md) {
        padding: 40px 0;
      }
      @include media-breakpoint-up(xl) {
        padding-top: 50px;
      }

      h4 {
        margin-bottom: 0;
      }
    }
    .abstract {
      top: 0;
      left: -40px;
      @include media-breakpoint-up(md) {
       width: 170px;
      }
      @include media-breakpoint-up(lg) {
        top: 20px;
        width: 250px;
      }
    }
  }



}
