// --------------------
// BUTTON STYLE
//---------------------

.btn,
.btn-sm,
input[type="submit"]:not(.search-submit),
input[type="button"] {
  border: 0;
  border-radius: 8.5px;

  @include font-responsive(false, false, 1.3rem, 1.5rem);

  font-weight: 700;
  line-height: 1;
  letter-spacing: 0;

  padding: 15px 30px;
  margin-bottom: 0;

  color: $body;
  background: $primary;
  box-shadow: none;

  display: table;
  width: auto;
  min-width: 192px;
  position: relative;
  white-space: normal;

  transition: background 0.4s ease;

  @include media-breakpoint-up(lg) {
    padding: 15px 30px;
    min-width: 226px;
  }

  &:hover,
  &:focus,
  &:active {
    background: rgba($primary, 0.8);
    color: $body;
  }

  &:disabled {
    background: rgba($primary, 0.6);
  }
}

// in my account
.btn-sm {
  padding: 10px 20px;
  font-size: 1.4rem;
}

.btn-container {
  max-width: 100%;
  &--center {
    .btn {
      margin: 0 auto;
    }
  }
  &--center-mobile {
    @include media-breakpoint-down(md) {
      .btn {
        margin: 0 auto;
      }
    }
  }
  .btn + p {
    width: 37ch;
    max-width: 100%;
    margin: 1rem auto 0;
    text-align: center;
    line-height: 1.4;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
  }

  &--xl  {
    .btn + p {
        width: 51ch;
        font-size: 1rem;
        max-width: 100%;
        @include media-breakpoint-up(lg) {
            margin-top: 2rem;
            font-size: 1.2rem;
            width: 51ch;
        }
    }
}
}
