@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}
@mixin width-responsive($width-small, $width-large) {
    width: $width-small;
    @include media-breakpoint-up(lg) {
        width: $width-large;
    }
}