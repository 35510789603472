.awards {
    position:relative;

    @include media-breakpoint-up(lg) {
        padding: 60px 0 75px;
        &--retail {
            padding-top: 85px;
        }
    }


    .abstract {
        position: absolute;
        &--1 {
            top: 12px;
            right: 0;
            width: 86px;
            height: auto;
            display: block;
            @include media-breakpoint-up(lg) {
                top: -60px;
                width: 220px;
            }
        }
    }

    &__inner {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    &__title {
        text-align: center;
        h2 {
            margin-bottom: 5px;

            @include font-responsive(false, false, 3.4rem, 5.6rem);
        }
        .font-fancy {
            @include font-responsive(false, false, 2.6rem, 4rem);
            margin-bottom: 0;
        }
        margin-bottom: 15px;
        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }

    }
    &__abstract {
        margin: 0 auto 15px;
        width: 145px;
        height: auto;

        @include media-breakpoint-up(md) {
            width: 222px;

        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
            transform: translateX(30px);
        }

        img {
            width: 100%;
            height: auto;

        }
    }

    &__awards {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 774px;
        max-width: 100%;
        padding: 0 20px;
        @include media-breakpoint-up(md) {
            padding: 0;
            flex-wrap: nowrap;
        }

        img {
            width: 96px;
            height: auto;
            margin: 10px 15px;
            image-rendering: -webkit-optimize-contrast;

            @include media-breakpoint-up(md) {
                margin: 0 15px;
            }   
        }
    }

    &--cafes-and-restaurants {
        .abstract {
            &--1 {
                display: none;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 40px;
        }
    }

}