form {
    label.error,
    .field-error {
        display: block;
        margin-bottom: 5px 0;
        color: red;
        font-size: 1.5rem;
    }
    .form-messages {
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}