.hero, .hero-centered {
    color: #443832;
    font-size: 1.4rem;
    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }

    h1 {

        @include media-breakpoint-down(xs) {
            font-size: 2rem;
            line-height: 2.2rem;
            width: 27ch;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .eyebrow {
        font-size: 1.8rem;
        line-height: 1.3;
        display: inline-block;

        margin-bottom: 1.5rem;
        @include font-responsive(false, false, 1.8rem, 3.6rem);
        @include media-breakpoint-up(lg) {
            margin-bottom: 2.5rem;
        }
    }
    .subheading {
        width: 100%;
        max-width: 71rem;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(xs) {
            line-height: 1.3;
            font-size: 1.3rem;
        }
    }

    .mob-image {
        margin: -10px 0 2rem -10px;
    }

    .btn-container {
        
        p {
            width: 100%;
            max-width: 660px;
            font-size: 1.2rem;
            color: #443832;
            opacity: 0.7;
            margin-top: 1rem;
        }
    }

    .decorations {
        img {
            position: absolute;
        }
        .dec-1 {
            left: 0px; 
            width: 80px;
            height: auto;
            top: 0px;
            @include media-breakpoint-up(md) {
                width: 160px; 
            }
        }
        .dec-2 {
            left: -50px;
            top: 37%;
            width: 120px;
            height: auto;
            opacity: 0.5;
            @include media-breakpoint-up(md) {
                opacity: 1;
                left: -70px; 
                top: 45%;
                width: 220px; 
            }
        }
        .dec-3 {
            right: -50px;
            width: 150px; 
            height: auto;
            top: 0px;
            @include media-breakpoint-up(md) {
                right: 0px; 
                width: 180px; 
            }
        }
        .dec-4 {
            height: 90px;
            width: auto;
            top: 25%;
            right: 0px; 
            @include media-breakpoint-up(md) {
                height: 160px; 
                top: 30%;
            }
        }
    }

}