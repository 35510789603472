// Has a big abstract image sometimes has a BG image

.copy-image--abstracted {
  padding-bottom: 85px;
  padding-top: 60px;

  @include media-breakpoint-up(lg) {
    margin-top: -70px;
    padding-top: 144px;
    padding-bottom: 133px;
  }

  &__image {
    img {
      width: 100%;
      height: auto;
    }

    width: 305px;
    margin-top: -30px;

    @include media-breakpoint-up(sm) {
      width: 450px;
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      width: 500px;
    }
    @include media-breakpoint-up(xl) {
      width: 616px;
    }
  }

  &__copy {
    padding: 0 55px 0 15px;
    p {
      margin-bottom: 1.8rem;
      padding-right: 20px;
    }
    @include media-breakpoint-up(sm) {
      width: 500px;
      padding-right: 15px;
      p {
        padding: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      width: 465px;
      margin-right: 40px;
      h3 {
        margin-bottom: 5rem;
        font-size: 3.3rem;
      }
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    br {
      display: none;
      @media screen and (min-width: 1200px) {
        display: block;
      }
    }
  }

  &__name {
    @include font-responsive(false, false, 3rem, 4.4rem);
    transform-origin: center center;
    display: block;
    transform: rotate(-5deg);
    margin-top: -10px;
    @include media-breakpoint-up(lg) {
      margin-left: 200px;
      margin-top: 2rem;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 224px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      flex-flow: row nowrap;
    }
  }

  // Page specific styles
  &--retail {
    @include media-breakpoint-up(xl) {
      margin-top: -90px;
    }
  }

  &--our-difference {
    color: white;
    @include media-breakpoint-up(lg) {
      margin-top: -30px;
      padding-top: 60px;
      .copy-image--abstracted__copy {
        margin-top: 30px;
        padding-right: 47px;
      }
    }

  }

  &--cafes-and-restaurants {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 30px;
    .background-image-container {
      display: none;
    }
    .copy-image--abstracted__inner {
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }
    .copy-image--abstracted__copy {
      width: 299px;
      padding: 0;
      p {
        padding: 0 25px;
      }
      h3 {
        text-align: center;
      }
      @include media-breakpoint-up(sm) {
        width: 500px;
        h3 {
          text-align: left;
        }
        p {
          padding: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        width: 465px;
        h3 {
          margin-bottom: 2rem;
        }
        margin-top: 90px;
        margin-right: 80px;
        margin-left: 20px;
      }
    }
    .copy-image--abstracted__image {
      margin-top: 0;
      @include media-breakpoint-up(xl) {
        width: 652px;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      margin-top: 0;
    }
  }


  // Occurence specific styles
  &--reset {
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-top: 50px;
      padding-bottom: 120px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }

    .copy-image--abstracted__copy {
        @include media-breakpoint-up(lg) {
            margin-top: 60px;
        }
    }
    .copy-image--abstracted__image {
        margin-top: -20px;
    }
  }

 
}
