.gallery {
  overflow: hidden;
  .abstract {
    position: absolute;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
    }
    &--1 {
      top: -5px;
      width: 75px;
      height:auto;
      left: 0;
      transform: translateX(-55%);
      @include media-breakpoint-up(md) {
        width: 200px;
      }
    }
    &--2 {
      top: 20px;
      width: 85px;
      height:auto;
      right: 0;
      transform: translateX(58%);
      @include media-breakpoint-up(md) {
        width: 211px;
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__title {
    width: 855px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
    h4 {
        @include media-breakpoint-up(lg) {
            font-size: 3.7rem;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
    }
  }

  &__inner {
    position: relative;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin-bottom: 2.4rem;
    width: 253px;
    @include media-breakpoint-up(md) {
        width: 737px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 5rem;
    }
  }

  &__image {
    width: (100% / 3);
    height: auto;
    margin: 0 2px;
    img {
      width: 100%;
      height: auto;
    }
    @include media-breakpoint-up(md) {
        margin: 0 5px;
    }
  }

  .btn + p {
    width: 51ch;
    max-width: 100%;
    font-size: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
      font-size: 1.2rem;
      width: 51ch;
    }
  }
}
