.swiper-container {
	width: 100%;
	&.mobile {
		@include media-breakpoint-up(lg) {
			pointer-events: none;
			.swiper-slide {
				flex-shrink: unset;
			}
		}
	}
	section.brands & {
		.swiper-wrapper {
			@include media-breakpoint-up(lg) {
				transform: unset !important;				
			}
		}
	}
	.swiper-slide {
		transition: .4s;
		.play-button p {
			max-width: 100%;
		}
		&.extra-slide {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	.swiper-pagination {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.swiper-pagination-bullet {
			outline: none;
			width: 1.8rem;
			height: 1.8rem;
			border-radius: 1.8rem;
			background: $secondary !important;
			opacity: 1;
			margin: 4px;
			&.swiper-pagination-bullet-active {
				background: $primary !important;
			}
		}
	}

	.swiper-button-prev, .swiper-button-next {
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: $primary;
		transition: .4s;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			width: 50px;
			height: 50px;
		}
		&:after {
			font-size: 2.5rem;
			color: $secondary;
			font-weight: bold;
		}
		img, svg {
			width: 25px;
			height: 25px;
		}
	}

	.swiper-button-prev {
		left: 0px;
		@include media-breakpoint-up(md) {
			left: 10px;
		}
	}
	.swiper-button-next {
		right: 0px;
		@include media-breakpoint-up(md) {
			right: 10px;
		}
	}

	&.gallery-thumbs {
		.swiper-slide {
			opacity: 0.9;
		}
		.swiper-slide-thumb-active {
			opacity: 1;
		}
	}
}
