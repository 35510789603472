// Base styles for all copy-image modules
.copy-image{
  position: relative;
    .abstract {
        img {
            width: 100%;
            height: auto;
        }
    }

    .container-design {
        @include media-breakpoint-up(sm) {
          max-width: 100%;
        }
        @include media-breakpoint-up(lg) {
          max-width: 1366px;
        }
      }


    h3 {
    }

    p {
        @include font-responsive(17, 20, 1.2rem, 1.4rem);
        margin: 0;
    }

    .font-fancy {
        @include font-responsive(false, false, 2.8rem , 5.1rem)
    }

}