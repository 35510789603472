// --------------------
// VARIABLES
//---------------------

$headerheight   : 160px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 180px;
$header-scrolled-width: 100px;

// Colors
$body-color		: #443832;
$primary        : #42c7b3;
$secondary      : #333;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$headerbg       : #fff; // Header scrolled background color.

$bg-table-active-color : #42c7b3;

$slider-title-color : #ffffff;
$slick-dot-color : #ffffff;
$slick-active-dot-color : #42c7b3;


// --------------------
// VARIABLES
//---------------------

// Colors
$primary        : #FFDFAA;
$secondary      : white;
$body           : #443832;
$headerbg       : #fff; // Header scrolled background color.

// various brand colours
$gray          : #F8F7F7;
$lightblue      : #54C6EB;
$extralightblue : #C5E5F2;
.color-lightblue {
  color: $lightblue;
}
$midblue        : #3A8CC3;
.color-midblue {
  color: $midblue;
}
$midblue-alt  : #5096CE;
.color-midblue-alt {
  color: $midblue-alt;
}
$teal           : #76BBB8;
.color-green {
  color: $teal;
}

$pink           : #F490AE;
.color-pink {
  color: $pink;
}
$orange         : #E48067;
.color-orange {
  color: $orange;
}
$midyellow      : #F9A870;
.color-midyellow {
  color: $midyellow;
}
$yellow          :#FFCB1F;
.color-yellow {
  color: $yellow;
}
$purple : #918BC3;
.color-purple {
  color: $purple;
}

$a-border       : #EEEEEE;
$activemenu    : #8ED8F8;
$pink-bg    : #DEB0D2;

// Page theme colors
$products-theme : $activemenu;
$wheretobuy-theme : #DEB0D2;
$retail-theme  : #7895C4;
$cafes-theme   : $midyellow;
$our-story-theme: $orange;
$our-difference-theme: #918BC3;




// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  ) !default;
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1140px
) !default;