.videoslider {
  position: relative;
  z-index: 1;
  padding: 60px 0 50px;

  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  .background-image-container {
    .bottom,
    .top {
      outline: 1px solid transparent;
    }

    .bottom {
      @include media-breakpoint-up(sm) {
        transform-origin: center center;
        transform: rotate(-180deg);
      }
    }
  }

  color: white;

  &__title {
    text-align: center;
    margin-bottom: 30px;
    h3 {
      margin: 0 auto;
      width: 25ch;
      @include font-responsive(false, false, 2rem, 3.4rem);
    }

    @include media-breakpoint-up(lg) {
      h3 {
        width: 35ch;
      }
      margin-bottom: 66px;
    }
  }

  .swiper-slide {
    width: 253px !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .play-button {
      opacity: 0.56;
      @include media-breakpoint-down(sm) {
        width: 80px;
      }
    }

    &__video {
      width: 100%;
      border: 5px solid white;
      border-radius: 20px;

      transform: scale(0.9);
      transition: transform 0.3s ease;

      @include media-breakpoint-down(sm) {
        padding-bottom: 90%;
        &.playing {
          margin-top: ((90% - 56.25%) / 2);
          padding-bottom: 56.25%;
        }
      }
    }

    &__copy {
      display: block;
      margin-right: auto;
      margin-top: 2rem;
      transition: padding 0.3s ease;

      p {
        width: 90%;
        line-height: 1.25;
        @include font-responsive(false, false, 1.2rem, 1.52rem);
      }
      h5 {
        @include font-responsive(false, false, 1.8rem, 2.85rem);
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(md) {
        p {
          width: 354px;
        }
        h5 {
          margin-bottom: 20px;
        }
        margin-top: 3rem;
        padding: 0 0 0 50px;
      }
    }

    &.swiper-slide-active {
      .play-button {
        opacity: 1;
      }
      .swiper-slide__video {
        transform: scale(1);
        transition: transform 0.3s ease;
      }
      .swiper-slide__copy {
        @include media-breakpoint-up(md) {
          padding: 0 15px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      width: 512px !important;
    }
  }

  .swiper-wrapper {
    position: relative;
  }

  .swiper-prev,
  .swiper-next {
    position: absolute;
    top: 38%;
    width: 47px;
    height: auto;
    transform: translate(-50%, -100%);
    img {
      width: 100%;
      height: auto;
    }
    z-index: 20;

    &.swiper-button-disabled {
      opacity: 0.5;
    }

    @include media-breakpoint-up(md) {
      width: 77px;
      top: 42%;
    }

    cursor: pointer;
  }
  .swiper-next {
    left: calc(50% + (253px / 2));
    @include media-breakpoint-up(md) {
      left: calc(50% + (520px / 2));
    }
  }
  .swiper-prev {
    left: calc(50% - (253px / 2));
    @include media-breakpoint-up(md) {
      left: calc(50% - (520px / 2));
    }
  }

  .btn-container {
    margin-top: 15px;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      .btn {
        min-width: 235px;
      }
      p {
        width: 235px;
      }
    }
  }

  &--cafes-and-restaurants {
    .background-image-container {
      mask-image: url('/assets/prod/images/videoslider-mask.png');
      mask-size: cover;
      mask-position: bottom center;

      .bottom {
        display: none !important;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 90px;
    }
  }
}
