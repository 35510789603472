// For section specific padding and margins.
// All of these need to be wrapped in .shopify-section when CMS'd
// https://shopify.dev/themes/architecture/sections/section-schema#tag

.hero-minor {

    &--cafes-and-restaurants {
        + .copy-image--leaning {
            @include media-breakpoint-up(lg) {
                padding-top: 75px;
            }
        }
    }
}

.copy-image {


    &--even {
        + .copy-image--even {
            @include media-breakpoint-up(lg) {
                padding-top: 65px;
            }
        }
    }

}