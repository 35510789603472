.copy {

    .abstract {
        position: absolute;
        &--1 {
            top: 0;
            right: 0;
            @include width-responsive(36px, 90px);
            height: auto;
            @include media-breakpoint-up(lg) {
                top: 10%;
            }
        }
        &--2 {
            bottom: 37%;
            left: 0;
            @include width-responsive(30px, 137px);
            height: auto;
            @include media-breakpoint-up(lg) {
                bottom: -20px;
            }
        }

        img {
            width: 100%;
            height: auto;
        }

    }

    &__title {
        width: 807px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;

        padding: 0 5px;
        @include media-breakpoint-up(sm) {
            padding: 0;
            margin-bottom: 1.5rem;
        }
    }

    &__inner {
        width: 617px;
        max-width: 100%;
        margin: 0 auto;



        .btn-container {
            .btn + p {
                @include media-breakpoint-down(md) {
                    width: 49ch;
                }
            }
        }
    }


}