.statistics {
    position: relative;
    z-index: 10;
    @include media-breakpoint-up(lg) {
        padding: 105px 0;
    }
    &__inner {
        width: 1010px;
        margin: 0 auto;
        padding: 0 15px;
        max-width: 100%;

        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__statistic {
        text-align: center;
        width: 200px;
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        margin: 30px 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            margin: 20px;
        }
        @include media-breakpoint-up(md) {
            width: 233px;
            margin: 0 20px;
        }
    }
    &__copy {
        margin-top: 1rem;
        p {
            font-weight: 700;
            line-height: 1.4;
        }
    }

    &__number {
        width: 100%;
        height: auto;
        position: relative;
        font-family: $headings-font;
        line-height: 1;
        @include font-responsive(false, false, 9rem, 12rem);
    }

    .abstract {
        position: absolute;
        height: auto;
        z-index: -1;

        &--1 {
            left: -20px;
            top: -50px;
            width: 65%;
        }
        &--2 {
            right: -30px;
            top: -40px;
            width: 80%;
            @include media-breakpoint-up(lg) {
                width: 60%;
            }
        }
        &--3 {
            right: -10px;
            top: -50px;
            width: 45%;
        }
    }
    .footnote {
        text-align: center;
        width: 100%;
        font-size: 1.2rem;
        width: 200px;
        margin: 0 auto;
        @include media-breakpoint-up(sm) {
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 1rem;
        }
    }


    &--cafes-and-restaurants {
        @include media-breakpoint-up(lg) {
            padding-top: 60px;
            padding-bottom: 75px;
        }
    }


}