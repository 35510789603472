// --------------------
// Include Fonts Here
// --------------------
@font-face {
  font-family: "Emmascript MVB Std";
  src: url("/assets/prod/fonts/EmmascriptMVBStd.woff2") format("woff2"),
    url("/assets/prod/fonts/EmmascriptMVBStd.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Scoder Hand";
  src: url("/assets/prod/fonts/ScoderHand.woff2") format("woff2"),
    url("/assets/prod/fonts/ScoderHand.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("/assets/prod/fonts/GothamRounded-Bold.woff2") format("woff2"),
    url("/assets/prod/fonts/GothamRounded-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("/assets/prod/fonts/GothamRounded-Book.woff2") format("woff2"),
    url("/assets/prod/fonts/GothamRounded-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

$headings-font: "Scoder Hand", Arial, sans-serif;
$fancy-font: "Emmascript MVB Std", Arial, sans-serif;
$body-font: "Gotham Rounded", Arial, sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin font-responsive(
  $line_mob,
  $line_desk,
  $mob_size: 1.2rem,
  $desk_size: 1.4rem
) {
  font-size: $mob_size;

  @include media-breakpoint-up(sm) {
    font-size: ($mob_size + $desk_size) / 2;
  }
  @include media-breakpoint-up(lg) {
    font-size: $desk_size;
  }
}

:root {
  font-size: 62.5%; // 10px
}

html,
body {
  @include font-responsive(12, 15, 1.2rem, 1.4rem);

  line-height: 1.4;
  font-family: $body-font;

  @include font-smoothing(on);

  h1,
  .h1 {
    @include font-responsive(false, false, 2.6rem, 5.2rem);
  }

  h2,
  .h2 {
    line-height: 1;
    @include font-responsive(false, false, 2rem, 4.2rem);
  }

  h3,
  .h3 {
    @include font-responsive(22, 39, 2rem, 3.6rem);
  }

  h4,
  .h4 {
    @include font-responsive(false, false, 2rem, 3.3rem);
  }

  h5,
  .h5 {
    @include font-responsive(false, false, 1.7rem, 2.5rem);
  }

  h6,
  .h6 {
    @include font-responsive(false, false, 1.6rem, 1.68rem);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: $headings-font;
    margin: 0 0 2rem;
    font-weight: normal;
    line-height: 1.2;
  }
  p {
    margin: 0 0 2.5rem;
  }
}

.mega-font {
  @include font-responsive(58, 102, 4.8rem, 8.5rem);
}

.font-fancy {
  font-family: $fancy-font;
  font-style: italic;
}

u {
  text-decoration: none;
  background: url("/assets/prod/images/underline.png");
  background-size: 100% 7px;
  background-position: center 110%;
  background-repeat: no-repeat;
  padding: 0px 10px;
  margin-left: -10px;
  margin-right: -10px;

  @include media-breakpoint-up(md) {
    background-position: center bottom;

    padding: 0px 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
