.offer {
    padding-bottom: 195px;
    @include media-breakpoint-up(lg) {
        padding: 100px 0 200px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 230px;
    }


    position: relative;
    .abstract {
  
      &--1 {
        position: absolute;
        width: 173px;
        height: auto;
        bottom: -25px;
        right: 0;
  
        @include media-breakpoint-up(md) {
          width: 310px;
          bottom: 0;
        }
  
        @include media-breakpoint-up(xl) {
          width: 410px;
        }
      }
      &--2 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        @include media-breakpoint-up(xl) {
          &.loaded {
            bottom: -1vw;
          }
        }
      }
    }

  &__title {
    text-align: center;
    margin: 0 auto;

    width: 797px;
    max-width: 100%;

    padding: 0 15px;

    img {
      @include width-responsive(93px, 191px);
      height: auto;
      display: block;
      margin: 0 auto;
    }

    h2.h1 {
      @include font-responsive(false, false, 3rem, 5.6rem);
      margin-top: 1rem;
      margin-bottom: 2rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 45px;
      }
    }
    p {
      @include font-responsive(false, false, 2rem, 3rem);
      line-height: 1.2;

      @include media-breakpoint-up(lg) {
          padding: 0 50px;
      }
    }

    @include media-breakpoint-up(lg) {
        padding: 0;
        margin-bottom: 2rem;
    }
  }

  &__inner {
    width: 565px;
    max-width: 100%;
    margin: 0 auto;

    * {
      width: 100%;
    }
  }

  &__intro {
      margin-bottom: 1rem;
      p {
          line-height: 1.4;
          @include font-responsive(false, false, 1.2rem, 1.6rem);
        margin-bottom: 2rem;
      }
      padding: 0 15px;
      @include media-breakpoint-up(lg) {
          padding: 0;
          margin-bottom :2rem;
      }
  }

  &__list {

    padding: 0 0 30px;;
    ~ p:last-of-type {
      margin-bottom: 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px 0 15px;

      ~ p:last-of-type {
        margin-bottom: 40px;
      }
    }

    .intro {
      @include font-responsive(false, false, 1.3rem, 2rem );
      max-width: 80%;
      margin-bottom: 25px;
      @include media-breakpoint-up(sm) {
        max-width: 100%;
        margin-bottom: 50px;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding:0 ;

      li {
        display: flex;
        flex-flow: row nowrap;

        .image {
          width: 46px;
          height: auto;
          margin-right: 10px;
          img {
            width: 100%;
            height: auto;

          }
          @include media-breakpoint-up(lg) {
            width: 67px;

          }
        }
        .copy {
          p {
            margin: 0;
          }
          strong {
            display: block;
          }
        }
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
          padding-right: 28px;
          margin-bottom: 45px; 
        }
      }
    }
  }

  &__form {
    .form-field {
        position: relative;
      margin-bottom: 1rem;
      .icon {
          position: absolute;
          top: 50%;
          width: 12px;
          left: 20px;
          transform: translateY(-50%);
          height: auto;
          &-message {
              top: 20px;
          }
          svg {
              width: 100%;
              height: auto;
          }
          @include media-breakpoint-up(md) {
            left: 40px;
             width: 20px;
            &-message {
                top: 30px;
            }
          }
      }
      input, textarea {
        appearance: none;
        border: none;
        box-shadow: none;

        color: $body-color;

        &::placeholder {
            color: $body-color;
        }

        @include font-responsive(false, false, 1.2rem, 2rem);

        border-radius: 10px;
        background: rgba($extralightblue, 0.2);
        padding: 12px 10px 12px 50px;

        @include media-breakpoint-up(md) {
          padding: 20px 10px 20px 85px;
        }
      }
      textarea {
          height: 76px;;
          @include media-breakpoint-up(lg) {
              height: 154px;
           
          }
      }

    }
    .btn-container {
        .btn + p {
            @include font-responsive(false, false, 1.2rem, 1.4rem);
            width: 49ch;
            @include media-breakpoint-up(lg) {
                width: 49ch;
            }
        }
    }
  }
}
