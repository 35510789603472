// --------------------
// GLOBAL COMPONENTS
//---------------------

body {
  width: 100vw;
  overflow-x: hidden;
}

section:first-of-type {
  body.fixed-header & {

    
    padding-top: 125px;
    &.hero-minor {
      margin-top: 63px;
      padding: 30px 0;
      &--where-to-buy {
        padding: 85px 0 30px;
      }
      &--retail {
        padding: 85px 0 55px;
      }
      &--cafes-and-restaurants { 
        padding: 105px 0 45px;
      }
    }
    transition: padding-top 300ms ease;

    @include media-breakpoint-up(md) {
      //padding-top: px;
      &.hero-minor {
        padding: 100px 0;
        &--where-to-buy {
          padding-bottom: 75px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      padding-top: 100px; // height of header
      &.hero-minor {
        margin-top: 100px;
        &--retail, &--cafes-and-restaurants {
          margin-top: 120px;
          padding-bottom: 65px;
          padding-top: 120px;
        }
      }
    }
  }

  body.has-announcements & {
    padding-top: 182px;

    @include media-breakpoint-up(md) {
      padding-top: 188px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 205px;
    }
  }
}

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
  padding: 30px 0px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding: 40px 0px;
  }
}

.container-design {
  @include make-container();
  @include make-container-max-widths();

  @include media-breakpoint-up(xl) {
    max-width: 1366px;
  }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;

    &.bottom-aligned {
      object-position: bottom center;
    }
    &.bottom, &.top {
      width: 100%;
      height: auto;
      object-fit: unset;
      position: absolute;
      left: 0;
    } 
    &.top {
      top: 0; 
    }
    &.bottom {
      bottom: 0;
    }
  }

  // Background Overlay - Adds a colour overlay with transparency options
  &.semi-transparent-bg {
    &-dark {
      background: #000;
      img {
        opacity: 0.6;
      }
    }
    &-light {
      background: #fff;
      img {
        opacity: 0.4;
      }
    }
  }
}

// Youtube Video Embeds
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: auto;
  overflow: hidden;

  iframe,
  object,
  embed, .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .overlay {
    object-fit: cover;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translateY(-50%) translateX(-50%);
    cursor: pointer;
    transition: transform 0.3s ease;

    width: 47px;
    height: auto;

    svg {
      width: 100%;
      height: auto;
    }

    @include media-breakpoint-up(md) {
      width: 106px;
    }

    &:hover {
      transform: translateY(-50%) translateX(-50%) scale(1.1);
    }
  }
}

.max-width-image {
  max-width: 400px;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}

.breadcrumb {
  padding: 15px;

  a,
  span {
    margin-right: 10px;
  }
}


.img-render-crisp {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}


picture {
  width: auto;
  height: auto;
}