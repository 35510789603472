.steps {
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 95px 0 0;
  }

  .abstract {
    position: absolute;
    &--1 {
      top: -40px;
      right: 0;
      width: 200px;
      height: auto;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
    &--2 {
      bottom: 7%;
      left: auto;
      height: auto;
      display: block;
      @include width-responsive(50px, 160px);
    }
  }

  &--purple {
    .steps__step {
      &--1 {
        background-color: #bdb9db;
      }
      &--2 {
        background-color: #b2aed5;
        .bg {
          filter: brightness(1.1);
        }
      }
      &--3 {
        background-color: #a7a2cf;
        .bg {
          filter: brightness(1.2);
        }
      }
      &--4 {
        background-color: #9c97c9;
        .bg {
          filter: brightness(1.3);
        }
      }
      &--5 {
        background-color: #918bc3;
        .bg {
          filter: brightness(1.4);
        }
      }
    }
  }

  &--with-bg {
    padding-bottom: 50px;
    padding-top: 60px;
    @include media-breakpoint-up(md) {
      padding-bottom: 90px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 120px;
      padding-top: 130px;
      margin-top: 30px;
    }
    .steps {
      &__title {
        color: white;
        text-align: center;
        padding-right: 0;
        padding: 0 20px;
        @include media-breakpoint-up(sm) {
          padding: 0;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: 2.5rem;
        }
      }
      &__step {
        background: white;
        color: $body;
      }
    }
    .btn-container {
      color: white;
    }
    .abstracts {
      display: none;
    }
  }

  &--orange {
    .steps {
      &__step {
        .top,
        .bottom {
          // Converts the white PNG to orange
          filter: brightness(0) saturate(100%) invert(80%) sepia(28%)
            saturate(1889%) hue-rotate(307deg) brightness(85%) contrast(111%);
        }
        .bg {
          display: none;
        }

        @include media-breakpoint-up(lg) {
          padding: 20px 60px 25px 0;
        }
      }
      &__number {
        color: $midyellow;
        padding: 0;
        height: auto;
        span {
          line-height: 1;
        
        }
        @include media-breakpoint-up(lg) {
          span {
            line-height: 1;
          }
          height: auto;
        }
        @include font-responsive(false, false, 3.5rem, 6rem);
      }
      &__copy {
        p {
          strong {
            @include font-responsive(false, false, 1.2rem, 1.8rem);
            @include media-breakpoint-up(lg) {
              margin-bottom: 2.5rem;
            }
          }
        }
      }
    }
  }

  &--green {
    @include media-breakpoint-up(lg) {
      padding-top: 90px;
    }
    .steps {
      &__step {
        .top,
        .bottom {
          // Converts the white PNG to green
          filter: brightness(0) saturate(100%) invert(90%) sepia(19%)
            saturate(319%) hue-rotate(112deg) brightness(88%) contrast(89%);
        }
        @include media-breakpoint-up(lg) {
          padding-right: 33px;
        }
      }
      &__number {
        color: white;

        .bg {
          filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
            saturate(586%) hue-rotate(132deg) brightness(95%) contrast(96%);
        }
      }
    }
    .btn-container {
      .btn + p {
        width: 51ch;
        font-size: 1rem;
        color: $body;
        @include media-breakpoint-up(lg) {
          margin-top: 2rem;
          font-size: 1.2rem;
          width: 51ch;
        }
      }
    }
  }

  &--alt-1 {
    margin-top: 30px;
    .steps {
      &__title {
        h4 {
          @include media-breakpoint-up(lg) {
            font-size: 4.2rem;
          }
        }
        p {
          width: 34ch;
          max-width: 100%;
          text-align: left;
          margin: 0 auto;
        }
        margin-bottom: 50px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 40px;
          p {
            font-size: 1.7rem;
          }
        }
        padding: 0;
      }
      &__step {
      }
      &__copy {
        strong:first-of-type {
          margin-bottom: 0.5rem;
          @include font-responsive(false, false, 1.4rem, 2rem);
          @include media-breakpoint-up(lg) {
            margin-bottom: 2.6rem;
          }
        }
      }
      &__number {
        @include media-breakpoint-up(lg) {
          font-size: 8rem;
        }
      }
    }
    .btn + p {
      width: 51ch;
      max-width: 100%;
      font-size: 1rem;
      @include media-breakpoint-up(lg) {
        margin-top: 2rem;
        font-size: 1.2rem;
        width: 51ch;
      }
    }
  }

  &__inner {
    @include width-responsive(600px, 750px);
    max-width: 100%;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include media-breakpoint-up(lg) {
      padding: 0 15px;
    }
  }

  &__title {
    padding-right: 20px;
    margin-bottom: 0.5rem;
    h2 {
      @include font-responsive(false, false, 2rem, 4rem);
    }
    @include media-breakpoint-up(sm) {
      padding-right: 0;
    }
    @include media-breakpoint-up(md) {
      text-align: center;
      margin-bottom: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
      margin-bottom: 5rem;
    }
    @include media-breakpoint-down(xs) {
      br {
        display: none;
      }
    }
  }

  &__step {
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px 10px 30px 0;
    margin-bottom: 2.2rem;
    display: flex;
    align-items: center;
    border-radius: 20px;
    color: white;

    @include media-breakpoint-up(lg) {
      padding: 40px 60px 45px 0;
    }

    .background-image-container {
      z-index: 1;

      @include media-breakpoint-up(sm) {
        .bottom {
          bottom: -3px;
        }
        .top {
          top: -6px;
        }
      }
    }
  }

  &__copy {
    p {
      margin: 0;
      line-height: 1.4;
      strong:first-of-type {
        display: block;
      }
      @include font-responsive(false, false, 1.2rem, 1.6rem);
    }
  }

  &__number {
    flex-shrink: 0;
    //padding: 10px 0;
    @include size(35px, 35px);
    @include font-responsive(false, false, 2rem, 3.3rem);
    font-family: $headings-font;
    margin: 0 13px;
    text-align: center;
    position: relative;
    span {
      position: relative;
      line-height: 40px;
      z-index: 1;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 40px;
      @include size(60px, 60px);
      span {
        line-height: 70px;
      }
      //  padding: 15px 0;
    }
  }

  &__button {
    margin-top: 2.2rem;
    max-width: 100%;
  }
}
