// Either leaning to the left or right on desktop
.copy-image--leaning {
  @include media-breakpoint-up(lg) {
    padding: 30px 0;
  }

  .abstract {
    position: absolute;
    &--1 {
        width: 61px;
        height: auto;
        top: 0px;
        right: 0;

        @include media-breakpoint-up(xl) {
            top: -40px;
            width: 244px;
        }
    }

    height: auto;
}

  &.reverse {
    &--copy-last-mobile {
      .copy-image--leaning__copy {
        order: 2;
        padding-right: 30px;
        .btn-container {
          margin-right: -30px;
        }
      }
      .copy-image--leaning__image {
        order: 1;
        margin-bottom: 2.5rem;

      }
    }
    @include media-breakpoint-up(lg) {
      .copy-image--leaning__inner {
        justify-content: flex-end;
      }
      .copy-image--leaning__copy {
        order: 1;
        width: 500px;
        margin-right: 70px;
        margin-left: 15px;
      }
      .copy-image--leaning__image {
        order: 2;
      }
    }
    @include media-breakpoint-up(xl) {
      .copy-image--leaning__copy {
        p {
          padding-right: 30px;
        }
      }
    }
  }


  &__inner {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;

    @include media-breakpoint-up(420) {
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    max-width: 100%;
    order: 1;
    margin-bottom: 27px;
    img {
      width: 100%;
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 535px;
      margin-bottom: 0;
    }

    .single-source {
      position: absolute;
      bottom: 0;
      right: 25px;
      height: auto;
      width: 60px;
      transform: translateY(40%);
      z-index: 1;
      @include media-breakpoint-up(lg) {
        width: 120px;
        transform: translate(40%, 30%);
        bottom: 0;
      right: 0;
      }
    }
  }

  &__copy {
    max-width: 100%;
    padding: 0 50px 0 15px;
    order: 2;
    width: 400px;
    br {
      display: none;
    }
    p {
      margin-bottom: 2rem;
    }
    .btn-container--center-mobile {
      margin-right: -50px;
    }

    @media screen and (min-width: 420px) {
      padding: 0;
      .btn-container--center-mobile {
        margin: 0;
      }
    }
    @include media-breakpoint-up(sm) {
      width: 500px;
    }
    @include media-breakpoint-up(lg) {
      width: 445px;
      margin-left: 85px;
      padding: 0;
    }
    @media screen and (min-width: 1200px){
      br {
        display: block;
      }
    }

  

  }

  .btn-container {
    order: 3;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
    }
  }

  &--retail {
    padding-top: 55px;

    @include media-breakpoint-up(lg) {
      padding-top: 95px;
    }

    .copy-image--leaning__copy {
      order: 1;
      p {
        line-height: 1.4;
      }
      @include media-breakpoint-up(lg) {
        width: 500px;
        order: 2;
        margin-top: 30px;
        h3 {
          font-size: 3.3rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
      @media screen and (min-width: 1200px){
        width: auto;
      }
    }
    .copy-image--leaning__image{
      order: 2;
      margin-top: 15px;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        min-width: 400px;
        order: 1;

      }
    }
  }

  &--cafes-and-restaurants {
    .copy-image--leaning__copy {
      order: 1;
      .btn-container {
        display: block !important;
      }
      p {
        line-height: 1.4;
      }
      @include media-breakpoint-up(lg) {
        order: 2;
        width: 560px;
      }
      @media screen and (min-width: 1200px){
        width: auto;
      }
    }
    .copy-image--leaning__image{
      order: 2;
      margin-bottom: 0;
      .single-source {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        width: 592px;
        order: 1;
      }
    }
    .copy-image--leaning__inner {
      > .btn-container {
        display: none !important;
      }
    }
    .abstract {
      &--1 {
        display: none;
      }
    }

    .btn {
      @include media-breakpoint-down(sm) {
        padding: 10px 30x;
      }
    }

  }

  &--alt-1 {
    padding-bottom: 0;
    @include media-breakpoint-up(xl) {
      padding-top: 130px;
    }
    .abstract {
      &--1 {
        @include media-breakpoint-up(lg) {
          width: 90px;
        }
        @include media-breakpoint-up(xl) {
          width: 167px;
        }
      }
    }
    .copy-image--leaning__image{
      @include media-breakpoint-up(xl) {
        width: 564px;
      }
    }
    .copy-image--leaning__copy {
      @include media-breakpoint-up(lg) {
        margin-left: 60px;
        margin-top: 15px;
      }
    }
  }

  &--alt-2 {
    .copy-image--leaning__image{
      order: 1;
      margin-bottom: 20px;
     }
     .copy-image--leaning__copy{
      order: 2;
      @include media-breakpoint-down(xs) {
        width: 254px;
        padding: 0;
        p {
          padding: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        margin-top: 80px;
        margin-left: 70px;
      }
     }
  }

  
  &--copy-last-mobile {
    .copy-image--leaning__copy {
      order: 2;
      padding-right: 30px;
      .btn-container {
        margin-right: -30px;
      }
    }
    .copy-image--leaning__image {
      order: 1;
      margin-bottom: 2.5rem;
    }
  }


  &--show-abstract-2 {
    overflow: visible;
    position: relative;
    z-index: 20;
    @include media-breakpoint-up(lg) {
      padding-top: 65px;
    }
    .abstract--2 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        width: 100px;
        right: 0;
        top: -60px;
      }
      @media (min-width: 1330px) {
        width: 157px;
        top: -60px;
      }
    }
  }

}
