.guarantee {
    overflow: hidden;
    margin-top: -60px;
    padding-top: 65px; 
    @include media-breakpoint-up(lg) {
        padding-top: 150px;
        padding-bottom: 80px;
    }

    .background-image-container {
        @include media-breakpoint-down(sm) {
            .bg {
                object-position: right center;
            }
        }
    }

    .abstract {
        position: absolute;
        z-index: -1;
        img {
          width: 100%;
          height: auto;
        }
        &--1 {
          top: 50%;
          width: 75px;
          left: 0;
          transform: translateX(-65%);
          @include media-breakpoint-up(md) {
            width: 200px;
            top: 25%;
          }
        }
        &--2 {
          top: 15px;
          width: 85px;
          right: 0;
          transform: translateX(58%);
          @include media-breakpoint-up(md) {
            width: 243px;
            top: -20px;
            
          }
        }
      }
      

    &__guarantee {
        position: relative;
        margin: 0 auto;
    
        width: 123px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
    
        height: 140px;
    
        @include media-breakpoint-up(md) {
        }
    
        .bg {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
    
        .stars {
            width :32px;
            height: auto;
            margin-bottom: 3px;
    
            svg {
                width: 100%;
                height: auto;
            }
    
            @include media-breakpoint-up(md) {
                margin-bottom: 10px;
                width: 54px;
            }
        }
    
         span {
             display: block;
         }
    
        .title {
            @include font-responsive(16, 26, 1.7rem, 3rem);
            font-family: $headings-font;
            width: 12ch;
            line-height: 1;
        }
        .subtitle {
            @include font-responsive(15, 26,  1.6rem, 2.8rem);
            margin-top: -5px;

        }
        
    
        @include media-breakpoint-up(sm) {
          width: 276px;
          height: 230px;
        }
    }

    &__title {
        text-align: center;
        width: 100%;
        margin-bottom: 15px;

        h3 {
            width: 29ch;
            max-width: 100%;
            margin: 0 auto;
        }
    }


    &__image {
        position: relative;
        width: 252px;
        height: auto;
        margin: 0 auto;
        img {
            width: 100%;
            height:auto;
        }

        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }

    &__copy {
        padding: 15px 0 0;
       > p {
            padding: 0 20px 0 0;
        }
        @include media-breakpoint-up(md) {
           > p {
                padding: 0;
            }
            padding: 30px 30px 10px;
        }
        @include media-breakpoint-up(lg) {
            padding: 30px 50px 10px;
        }
    }

    &__inner {
        width: 676px;
        max-width: 100%;
        margin: 0 auto;
    }




}