header {
  padding: 8px 15px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;

  &.scrolled {
    box-shadow: -3px -3px 6px 8px rgba(0, 0, 0, 0.05);
    background-color: $headerbg;
    transition: all 0.4s ease-in-out;
    padding: 20px 15px 30px;
  }
}
header + * {
  padding-top: $headerheight * 0.7;
  @include media-breakpoint-up(md) {
    padding-top: $headerheight;
  }
}

.header-logo img {
  margin-top: 20px;
  transition: 0.4s ease-in-out;
  // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
  // filter: invert(1);

  // Default to small scrolled size on mobile
  width: 70px;
  @include media-breakpoint-up(md) {
    width: $header-scrolled-width;
  }

  // Turn on the header resize on desktop
  @include media-breakpoint-up(md) {
    width: $header-starting-width;
  }
  .scrolled & {
    // filter: none;
    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
      width: $header-scrolled-width;
    }
    margin-top: 0px;
    margin-bottom: -21px;
  }
}

.header-phone {
  line-height: 1.2;

  .phone-icon {
    .phone-bg {
      position: absolute;
      z-index: 1;
      width: 40px;
      @include media-breakpoint-up(md) {
        width: 60px;
      }
    }
    svg {
      position: relative;
      max-width: 40px;
      width: 100%;
      z-index: 2;
      padding: 9px;
      @include media-breakpoint-up(md) {
        max-width: 60px;
        padding: 15px;
      }
    }
  }

  // Got questions (if it exists)
  // span {

  // }
  // Phone number text
  .phone-text {
    color: #000;
    margin-left: 0.3rem;
    span {
      text-transform: uppercase;
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
      margin-top: 0.4rem;
    }
    em {
      font-size: 2.3rem;
      font-style: normal;
      font-weight: bold;
    }
  }
}
